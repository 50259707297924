import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { RedGrungeBackground } from "./styled";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/pagination";
import { Description } from "../../Typography";
import bgImage from "../../../images/bg.jpeg";
import { bp } from "../../../styles/breakpoints";
import { useSlideController, useSlideName } from "../../layout";
import tw, { css } from "twin.macro";
import video from "../../../video/video.mp4";
import videoWebM from "../../../video/videowm.webm";
import preload from "../../../video/preload.jpg";
import { useMobile } from "../../../styles/screens";

const MotionDescription = motion(Description);
const bg = css`
  background: url(${ bgImage }) no-repeat 43% 0;
  background-size: cover;

  ${ bp.xl } {
    background-position: center;
  }
`;

export function MainPage() {
  const ctx = useSlideName();
  const { setSlideIndex } = useSlideController();
  const isMobile = useMobile();
  return (
    <motion.section
      onViewportEnter={ () => ctx.setSlideName("Building Playable DeFi") }
      id={ "main" }
      // css={ bg }
      tw={
        "w-full min-h-screen relative grid justify-center z-0 pt-[18vh] xl:pt-[14vh] 2xl:h-screen 2xl:pt-0 2xl:items-center"
      }
    >
      {/*<StaticImage*/ }
      {/*  tw={ "absolute top-0 left-0 w-full h-full object-[38% 0] xl:object-left-top" }*/ }
      {/*  src={ "../../../images/sky.png" }*/ }
      {/*  placeholder={ "none" }*/ }
      {/*  loading={ "eager" }*/ }
      {/*  alt={ "sky" }*/ }
      {/*/>*/ }
      {/*<StaticImage*/ }
      {/*  tw={ "absolute bottom-0 left-0 w-full h-full object-left" }*/ }
      {/*  src={ "../../../images/bg.jpeg" }*/ }
      {/*  imgClassName={ "object-left object-scale-down" }*/ }
      {/*  placeholder={ "none" }*/ }
      {/*  objectFit={ "cover" }*/ }
      {/*  loading={ "eager" }*/ }
      {/*  alt={ "background" }*/ }
      {/*/>*/ }

      {/*<motion.div*/ }
      {/*  tw={ "hidden xl:block absolute bottom-0 right-[5%]" }*/ }
      {/*  initial={ { opacity: 0, scale: 0.7 } }*/ }
      {/*  whileInView={ { opacity: 1, scale: 1 } }*/ }
      {/*  viewport={ { margin: "-50px" } }*/ }
      {/*  transition={ { delay: 0.2 } }*/ }
      {/*>*/ }
      {/*  <StaticImage*/ }
      {/*    tw={ "w-[340px] object-contain" }*/ }
      {/*    src={ "../../../images/knight-with-sword.png" }*/ }
      {/*    placeholder={ "none" }*/ }
      {/*    objectFit={ "contain" }*/ }
      {/*    loading={ "eager" }*/ }
      {/*    alt={ "logo" }*/ }
      {/*  />*/ }
      {/*</motion.div>*/ }
      <video
        autoPlay
        muted
        preload={ "auto" }
        playsInline
        poster={ preload }
        loop
        id="battle-video"
        tw={ "absolute inset-0 w-screen h-full object-cover" }
      >
        <source src={ videoWebM } type="video/webm"/>
        <source src={ video } type="video/mp4"/>
      </video>
      <motion.div
        tw={
          "w-full md:w-auto max-w-[650px] 2xl:max-w-[800px] flex flex-col items-center z-10"
        }
      >
        <motion.div
          initial={ { opacity: 0, y: -100 } }
          whileInView={ { opacity: 1, y: 0 } }
          transition={ { delay: 0.1, duration: 0.8, type: "spring" } }
          tw={ "mb-2" }
        >
          <StaticImage
            tw={
              "w-[70vw] max-w-[400px] object-contain 2xl:max-w-full 2xl:w-[45vh]"
            }
            src={ "../../../images/logo-horse.png" }
            placeholder={ "none" }
            objectFit={ "contain" }
            loading={ "eager" }
            alt={ "logo" }
          />
        </motion.div>

        <motion.p
          initial={ { opacity: 0, x: -200 } }
          whileInView={ { opacity: 1, x: 0 } }
          tw={
            "bg-primary text-white max-w-[90vh] w-[350px] xl:w-[400px] 2xl:w-[430px] mb-4 px-[3px] font-bold text-center 2xl:text-xl"
          }
        >
          ALPHA RELEASE ON MODE NETWORK
        </motion.p>

        <MotionDescription
          initial={ { opacity: 0, x: 200 } }
          whileInView={ { opacity: 1, x: 0 } }
          tw={
            "text-center max-w-[85vw] mb-[6vh] md:mb-6 xl:text-lg 2xl:text-xl"
          }
        >Making DeFi playable</MotionDescription>

        <a href={ isMobile ? "#Download" : "#" }>
          <motion.button
            tw={ "p-10 relative mb-4" }
            initial={ { opacity: 0, y: 100 } }
            whileInView={ { opacity: 1, y: 0 } }
            whileTap={ { scale: 0.9 } }
            whileHover={ { scale: 1.1 } }
            onClick={ !isMobile ? () => setSlideIndex(4) : undefined }
          >
            <RedGrungeBackground/>

            <div
              tw={
                "z-10 relative text-white font-bold relative 2xl:text-xl min-w-[180px]"
              }
            >
              Download
            </div>


          </motion.button>
        </a>

      </motion.div>

    </motion.section>
  );
}
