import React from "react";
import bg1 from "../../../images/background_for_questions1.svg";
import bg2 from "../../../images/background_for_questions2.svg";
import bg3 from "../../../images/background_for_questions3.svg";
import bg4 from "../../../images/background_for_questions4.svg";
import bg1Mobile from "../../../images/background_for_questions_MAX1.svg";
import bg2Mobile from "../../../images/background_for_questions_MAX2.svg";
import bg3Mobile from "../../../images/background_for_questions_MAX3.svg";
import bg4Mobile from "../../../images/background_for_questions_MAX4.svg";

export const faqData = [
  {
    question: "What is StableBattle?",
    answer: (
      <p>
        StableBattle is a real-time mass PVP tournament game with a prize pool generated from onchain yield, making DeFi playable.
      </p>
    ),
    bg: bg1,
    bgMobile: bg1Mobile,
  },
  {
    question: "What is BEER token?",
    answer: (
      <p>
        BEER token is a main and only one utility token of the game with deflationary model.
      </p>
    ),
    bg: bg2,
    bgMobile: bg2Mobile,
  },
  {
    question:
      "How does StableBattle generate prize pool?",
    answer: (
      <p>
        Our model uses DeFi services (such as Uniswap, Curve, etc.) to generate a yield from pooled player assets.
      </p>
    ),
    bg: bg3,
    bgMobile: bg3Mobile,
  },
  {
    question: "How to start to play?",
    answer: (
      <p>
        The character is a game ticket. Make a deposit - mint character. Burn character - withdraw the deposit.
      </p>
    ),
    bg: bg4,
    bgMobile: bg4Mobile,
  },
];
