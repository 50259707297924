import React from "react"
import { ContentWrapper, lineClump, MainBG } from "../../../styles/styled"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { MotionImageView } from "../../Motions/MotionImageView"
import { useSlideName } from "../../layout"
import { companiesGrid, company } from "./style"
import tw, { css } from "twin.macro"
import { useMobile } from "../../../styles/screens"

const Company = props => {
  const isMobile = useMobile()
  return (
    <motion.div
      css={company}
      variants={item}
      tw={"flex flex-col items-center gap-4"}
    >
      <motion.a
        href={props.href}
        target={"_blank"}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        tw={"bg-transparent grid place-items-center cursor-pointer"}
      >
        {props.image}
      </motion.a>
      {!isMobile && (
        <p
          css={[lineClump(5)]}
          tw={
            "leading-7 font-semibold text-center text-sm text-[#00365d] lg:text-base 2xl:text-lg"
          }
        >
          {props.children}
        </p>
      )}
    </motion.div>
  )
}

const container = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const item = {
  hidden: { opacity: 0, scale: 0.7 },
  show: { opacity: 1, scale: 1, transition: { duration: 0.4 } },
}

export function PartnersPage() {
  const ctx = useSlideName()

  return (
    <motion.section
      onViewportEnter={() => ctx.setSlideName("Partners")}
      viewport={{ margin: "-50%" }}
      id={"partners"}
      tw="w-full h-0 lg:h-auto min-h-screen xl:h-screen overflow-hidden"
      css={MainBG}
    >
      <ContentWrapper
        tw={
          "flex flex-col xl:flex-nowrap xl:flex-row justify-between items-center gap-x-12 px-[48px] xl:pl-24 overflow-hidden"
        }
      >
        <motion.div
          variants={container}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true }}
          tw={
            "xl:flex-auto mb-6 xl:mb-0 w-[90vw] lg:w-[60vw] xl:w-auto xl:ml-20 pt-[8vh] sm:pt-16"
          }
          css={companiesGrid}
        >
          <Company
            href={"https://7xvc.com/"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/sevenx.png"}
                alt={"SEVENX VENTURES"}
              />
            }
          >
            SEVENX VENTURES
          </Company>
          <Company
            href={"https://atka.io/"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/atka.png"}
                alt={"ATKA"}
              />
            }
          >
            ATKA
          </Company>
          <Company
            href={"https://brcapital.io/"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/br-capital.png"}
                alt={"BR.CAPITAL"}
              />
            }
          >
            BR.CAPITAL
          </Company>
          <Company
            href={"https://azuro.org/"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/azuro.png"}
                alt={"AZURO"}
              />
            }
          >
            AZURO
          </Company>
          <Company
            href={"https://metaguild.com/"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/metaguild.png"}
                alt={"METAGUILD"}
              />
            }
          >
            METAGUILD
          </Company>
          <Company
            href={"https://gametrade.market/"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/gametrade_market.png"}
                alt={"GAMETRADE.MARKET"}
              />
            }
          >
            GAMETRADE.MARKET
          </Company>
          <Company
            href={"https://mithraeum.io/"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/mithraeum.png"}
                alt={"MITHRAEUM"}
              />
            }
          >
            MITHRAEUM
          </Company>
          <Company
            href={"https://www.opgames.org/"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/opgames.png"}
                alt={"OPGAMES"}
              />
            }
          >
            OPGAMES
          </Company>
          <Company
            href={"https://Sbf.vc"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/sbf.png"}
                alt={"SBF"}
              />
            }
          >
            SBF
          </Company>
          <Company
            href={"https://www.bts.global/"}
            image={
              <StaticImage
                objectFit={"contain"}
                src={"../../../images/partners/bts-ventures.png"}
                alt={"BTS Ventures"}
              />
            }
          >
            BTS Ventures
          </Company>
        </motion.div>

        <MotionImageView
          tw={
            "w-[60vw] h-[60vw] md:h-[40vw] xl:h-[75vh] xl:w-auto lg:min-w-[450px] 2xl:min-w-[600px] mt-2 mb-10 xl:my-0"
          }
        >
          <StaticImage
            tw={"h-full w-full"}
            src={"../../../images/character-on-partners-page.png"}
            objectFit={"contain"}
            placeholder={"none"}
            alt={""}
          />
        </MotionImageView>
      </ContentWrapper>
    </motion.section>
  )
}
