import React from "react";
import { motion } from "framer-motion";
import tw, { css } from "twin.macro";

export const MotionImageView = (props) => {
  const { children, ...rest } = props;

  return (
    <motion.div
      initial={ { scale: 0.5, opacity: 0 } }
      whileInView={ { scale: 1, opacity: 1 } }
      viewport={ { once: true } }
      transition={ { duration: 0.6, type: "spring" } }
      { ...rest }
    >
      { children }
    </motion.div>
  );
};