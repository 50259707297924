import React, { useRef, useState } from "react"
import tw, { css, styled } from "twin.macro"
import { motion } from "framer-motion"
import buttonJoin from "../../images/button-join.png"
import { textShadow } from "../../styles/styled"
import bgInput from "../../images/background-for-mail.svg"
import { bp } from "../../styles/breakpoints"

function Form() {
  let [loading, setLoading] = useState(false)
  let [error, setError] = useState(false)

  const myForm = useRef(null)

  const handleSubmit = async e => {
    e.preventDefault()
    if (!myForm.current) return

    let data = new FormData(myForm.current)
    setLoading(true)

    const responce = await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    })

    if (!responce.ok) {
      setError(true)
    } else {
      setError(false)
      myForm.current.reset()
      window.open("https://faucet.stablebattle.io/")
    }
    setLoading(false)
  }

  return (
    <form
      ref={myForm}
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      tw={
        "flex flex-col items-center lg:justify-center lg:flex-row gap-2 mb-14 sm:mb-6 xl:mb-24 lg:w-full lg:pr-8"
      }
    >
      <input type="hidden" name="form-name" value="contact" />
      <Input
        error={error}
        type={"email"}
        name={"email"}
        required
        placeholder={"Your email address"}
      />
      <Submit
        whileHover={{ scale: 1.06 }}
        type={"submit"}
        whileTap={{ scale: 0.9 }}
        loading={loading}
      >
        JOIN
      </Submit>
    </form>
  )
}

const Input = styled.input(({ error }) => {
  return [
    tw`mb-2 lg:mb-0 h-[40px] lg:h-[50px] 2xl:max-w-[700px] 2xl:h-[70px] w-full lg:w-auto flex-auto max-w-[500px] p-4 px-8 focus:border-none focus:outline-none`,
    css`
      background: transparent url(${bgInput}) no-repeat top left;
      background-size: cover;

      ${error && "color: #d00909;"}
      &::placeholder {
        font-weight: 600;
        font-style: italic;
      }
    `,
  ]
})

const Button = styled.button(({ loading }) => [
  tw`block w-[100px] h-[36px] lg:w-[160px] lg:h-[50px] 2xl:h-[70px] 2xl:w-[200px] text-white font-bold 2xl:text-2xl`,
  css`
    background: url(${buttonJoin});
    background-size: cover;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.9);
    ${bp.exlg} {
      ${tw`text-3xl`}
    }
    ${textShadow}
  `,
  loading && tw`text-[rgba(142, 0, 0, 1)] shadow-none cursor-auto`,
])

const Submit = motion(Button)

export default Form
