import tw, { css, styled } from "twin.macro";
import { motion } from "framer-motion";
import bgInput from "../../../images/background-for-mail.svg";

export const socialIcon = css`
  width: 40px;
  cursor: pointer;
`;

export const BottomTitle = styled.p`
  ${ tw`text-[10px] sm:text-xs xl:text-base uppercase text-left` }
`;

export const BottomSubtitle = styled.p`
  ${ tw`text-[10px] sm:text-xs xl:text-base text-left` }
`;

export const BasementMenuItem = motion(styled.li`
  ${ tw`text-[16px] w-1/2 text-left text-white 2xl:text-xl font-bold whitespace-nowrap cursor-pointer hover:scale-[1.05] origin-center ease-in duration-100` };
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 1);
`);
