import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import { SwiperWrapper } from "./styled"
import { StaticImage } from "gatsby-plugin-image"
import rhombusRed1 from "../../../images/rhombus-red1.svg"
import rhombusRed2 from "../../../images/rhombus-red2.svg"
import rhombusRed3 from "../../../images/rhombus-red3.svg"
import rhombusGrey1 from "../../../images/rhombus-grey1.svg"
import rhombusGrey2 from "../../../images/rhombus-grey2.svg"
import rhombusGrey3 from "../../../images/rhombus-grey3.svg"
import { SliderButton } from "../../Slider/SliderButton"
import { ProjectText } from "./ProjectText"
import { ContentWrapper, MainBG } from "../../../styles/styled"
import { AnimatePresence, motion } from "framer-motion"
import { MotionTransitionWrapper } from "./MotionTransitionWrapper"
import { MotionImageView } from "../../Motions/MotionImageView"
import { MotionSliderButtonView } from "../../Motions/MotionSliderButtonView"
import { useSlideController, useSlideName } from "../../layout"
import tw, { css } from "twin.macro"

const MotionSwiperWrapper = motion(SwiperWrapper)

export function ProjectPage() {
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const swiperRef = React.useRef(null)
  const ctx = useSlideController()
  const ctxSlideName = useSlideName()

  return (
    <motion.section
      onViewportEnter={() => ctxSlideName.setSlideName("Project")}
      viewport={{ margin: "-50%" }}
      id={"project"}
      tw="w-full min-h-screen xl:h-screen overflow-hidden"
      css={MainBG}
    >
      <ContentWrapper
        tw={
          "flex flex-col xl:flex-row justify-between items-center gap-x-8 px-[16px] xl:px-24 overflow-hidden"
        }
      >
        <div
          tw={
            "max-w-[90vw] flex flex-col items-center mb-10 pt-[8vh] gap-6 sm:pt-32 xl:max-w-[70vw] xl:grid xl:grid-cols-[120px auto] xl:grid-rows-[auto auto] 2xl:gap-x-12"
          }
        >
          {/* <MotionSliderButtonView
            tw={
              "max-w-[85vw] col-span-1 row-span-1 flex xl:flex-col items-center justify-center gap-6 my-4"
            }
          >
            <SliderButton
              texture={rhombusGrey1}
              activeTexture={rhombusRed1}
              currentSlide={currentSlide}
              slide={0}
              onClick={() => swiperRef.current?.slideTo(0)}
            />
            <SliderButton
              texture={rhombusGrey2}
              activeTexture={rhombusRed2}
              currentSlide={currentSlide}
              slide={1}
              onClick={() => swiperRef.current?.slideTo(1)}
            />
            <SliderButton
              texture={rhombusGrey3}
              activeTexture={rhombusRed3}
              currentSlide={currentSlide}
              slide={2}
              onClick={() => swiperRef.current?.slideTo(2)}
            />
          </MotionSliderButtonView> */}

          <MotionSwiperWrapper
            initial={{ scale: 0.7, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3, duration: 0.6, type: "spring" }}
            tw={
              "col-start-2 col-end-3 row-start-1 row-end-2 order-first xl:order-none max-w-[90vw] xl:min-w-[550px] 2xl:max-w-[900px]"
            }
          >
            {/* <Swiper
              effect={"fade"}
              slidesPerView={1}
              autoplay={{
                delay: 5000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              onSwiper={swiper => {
                swiperRef.current = swiper
              }}
              onSlideChange={swiper => {
                setCurrentSlide(swiper.activeIndex)
              }}
              spaceBetween={0}
              modules={[Autoplay, EffectFade]}
              className="project-swiper"
            >
              <SwiperSlide>
                <StaticImage
                  tw={"w-full h-full"}
                  src={"../../../images/location-1.jpeg"}
                  placeholder={"none"}
                  alt={""}
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  tw={"w-full h-full"}
                  src={"../../../images/location-2.jpeg"}
                  placeholder={"none"}
                  alt={""}
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  tw={"w-full h-full"}
                  src={"../../../images/location-3.jpeg"}
                  placeholder={"none"}
                  alt={""}
                />
              </SwiperSlide>
            </Swiper> */}

            <div className="project-swiper">
              <StaticImage
                tw={"w-full h-full"}
                src={"../../../images/location-scheme.jpg"}
                placeholder={"none"}
                alt={""}
              />
            </div>
          </MotionSwiperWrapper>

          <motion.div
            initial={{ y: "30%", opacity: 0 }}
            whileInView={{ y: "0px", opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2, duration: 0.6, type: "spring" }}
            tw={"col-start-2 row-span-2 flex"}
          >
            <div
              tw={"max-w-[90vw] 2xl:max-w-[900px] text-justify overflow-hidden"}
            >
              <ProjectText>
                StableBattle is a multiplayer medieval hack and slash game that
                lets players earn financial rewards for playing well. These
                rewards are generated through a self-sustaining “stake to play
                and earn” GameFi model that adds a new dimension to gameplay.
                The player mints an NFT character by staking their stablecoins
                (i.e. temporarily locking in some of their savings), which will
                be put to work creating a yield via DeFi strategies. Most of
                this yield will be distributed back to winning players, while
                *all* players can take back their initial capital plus a small
                “thank you” yield.
              </ProjectText>
            </div>
          </motion.div>
        </div>

        <MotionImageView
          tw={
            "flex-auto w-[60vw] h-[60vw] md:h-[40vw] xl:h-[80%] xl:w-auto xl:min-w-[450px] xl:max-w-[600px] mt-2 mb-10 xl:my-0"
          }
        >
          <StaticImage
            tw={"w-full h-full"}
            src={"../../../images/character-on-project-page.png"}
            objectFit={"contain"}
            placeholder={"none"}
            layout={"constrained"}
            alt={""}
          />
        </MotionImageView>
      </ContentWrapper>
    </motion.section>
  )
}
