import React, { forwardRef } from "react"
import { TeamCard } from "./TeamCard"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Grid } from "swiper"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/grid"
import { SwiperWrapper } from "./styled"
import { motion } from "framer-motion"
import { useMobile } from "../../../styles/screens"
import { AVATAR_PREFIX, useTeamImages } from "../../hooks/useTeamImages"
import { teamData } from "./teamData"
import tw, { css } from "twin.macro"

const MotionImage = motion(StaticImage)
const MotionSwiperWrapper = motion(SwiperWrapper)

export const Slider = forwardRef((props, ref) => {
  const { onChange, isScal } = props
  const isMobile = useMobile()
  const imagesData = useTeamImages()

  return (
    <MotionSwiperWrapper
      initial={{ y: -100, scale: 0.4, opacity: 0 }}
      whileInView={{ y: 0, scale: 1, opacity: 1 }}
      viewport={{ once: true }}
      tw={"xl:h-[85vh] 2xl:h-[90vh]"}
    >
      <Swiper
        centeredSlides={false}
        slidesPerView={2.3}
        maxBackfaceHiddenSlides={5}
        normalizeSlideIndex={false}
        shortSwipes={false}
        longSwipesRatio={0.1}
        longSwipesMs={50}
        speed={200}
        updateOnImagesReady={false}
        grid={{
          rows: 2,
        }}
        breakpoints={{
          550: {
            slidesPerView: 3.5,
            grid: {
              rows: 2,
            },
          },
          780: {
            slidesPerView: 4.5,
            grid: {
              rows: 2,
            },
          },
          1280: {
            slidesPerView: 4,
            allowTouchMove: false,
            grid: {
              rows: 2,
            },
          }
        }}
        // autoplay={ {
        //   delay: 6000,
        //   pauseOnMouseEnter: true,
        //   disableOnInteraction: false,
        // } }
        onSwiper={swiper => {
          ref.current = swiper
        }}
        onSlideChange={swiper => {
          onChange(swiper.activeIndex)
        }}
        modules={[Grid, Autoplay]}
        className="team-swiper"
      >
        {teamData.map(item => {
          const images = imagesData.find(
            data => data.name === AVATAR_PREFIX + item.imageName
          )

          if (!images) return null
          const avatar = getImage(images.avatar)
          const scal = getImage(images.scal)
          return (
            <SwiperSlide key={item.name}>
              <TeamCard
                isScal={isScal}
                avatar={
                  <GatsbyImage
                    image={avatar}
                    objectFit={"contain"}
                    loading={ "eager" }
                    placeholder={"blurred"}
                    alt={"avatar"}
                  />
                }
                scalAvatar={
                  <GatsbyImage
                    image={scal}
                    objectFit={"contain"}
                    loading={ "eager" }
                    placeholder={"blurred"}
                    alt={"avatar"}
                  />
                }
                {...item}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </MotionSwiperWrapper>
  )
})
