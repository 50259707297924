import React from "react";
import { motion } from "framer-motion";
import tw, { css } from "twin.macro";

export const MotionTransitionWrapper = (props) => {
  const { children, ...rest } = props;

  return (
    <motion.div
      tw={ "w-full h-full" }
      initial={ { filter: "blur(10px)", display: "none" } }
      animate={ { filter: "blur(0px)", display: "block" } }
      transition={ { duration: 1, type: "spring" } }
      { ...rest }
    >
      { children }
    </motion.div>
  );
};