import React, { Children, cloneElement } from "react";
import bgAnswers from "../../../images/background_for_answers.svg";
import { StaticImage } from "gatsby-plugin-image";
import { bp } from "../../../styles/breakpoints";
import bgAnswersMobile from "../../../images/background_for_answers_MAX.svg";
import tw, { css } from "twin.macro";

export function Collapse(props) {
  const { title, description, open, onChange, bg, bgMobile } = props;

  return (
    <div tw={ "xl:max-w-[70vw] w-full" }>
      <div
        tw={ "min-h-[34px] 2xl:min-h-[50px] text-white text-[12px] font-medium md:text-sm lg:text-base 2xl:text-xl cursor-pointer flex items-center gap-1 px-2 md:px-4 z-20 relative leading-[0.9rem] 2xl:leading-[1.2rem] text-left" }
        css={ css`
          background: url(${ bgMobile }) no-repeat;
          background-size: cover;

          ${ bp.md } {
            background: url(${ bg }) no-repeat;
            background-size: cover;
          }
        ` }
        onClick={ () => onChange(!open) }
      >

        { open ? <StaticImage
            tw={ "h-[16px] w-[16px] sm:h-[30px] sm:w-[30px] sm:mr-1 flex-shrink-0" }
            src={ `../../../images/hare_actively.png` }
            placeholder={ "blurred" }
            loading={ "eager" }
            alt={ "hare" }
          /> :
          <StaticImage
            tw={ "h-[16px] w-[16px] sm:h-[30px] sm:w-[30px] sm:mr-1 flex-shrink-0" }
            src={ `../../../images/hare_inactive.png` }
            placeholder={ "blurred" }
            loading={ "eager" }
            alt={ "hare" }
          />
        }
        { title }

      </div>

      { open &&
        <div
          tw={ "text-white text-left text-[10px] font-medium md:text-xs lg:text-sm lg:text-base 2xl:text-xl px-7 sm:px-12 md:px-14 lg:px-4 py-2 origin-top z-10 relative" }
          css={ css`
            background: url(${ bgAnswersMobile }) no-repeat;
            background-size: cover;

            ul {
              list-style: decimal;
              padding-left: 1.5rem;
            }

            ${ bp.md } {
              background: url(${ bgAnswers }) no-repeat;
              background-size: cover;
            }

            h3, h4, h5, h6 {
              font-style: italic;
              font-weight: bold;
            }

            h3, h4, h5, h6, p, span, ul {
              margin-bottom: .5rem;
              line-height: 1.1rem;
            }

            ${ bp.md } {
              h3, h4, h5, h6, p, span, ul {
                line-height: 1.5rem;
              }

            ${ bp.xxl } {
              h3, h4, h5, h6, p, span, ul {
                line-height: 1.8rem;
              }
            }
          ` }
        >
          { props.children }
        </div> }

    </div>
  );
}


export function CollapseGroup(props) {
  const { children } = props;
  let [ index, setIndex ] = React.useState(false);
  return (
    <div tw={ "flex flex-col items-stretch gap-[6px] xl:gap-2" }>
      { Children.map(children, (child, i) => {
        return cloneElement(child, {
          open: index === i,
          onChange: (open) => setIndex(open ? i : false),
          title: child.props.title,
          description: child.props.description,
          i,
        });
      }) }
    </div>
  );
}
