import React from "react";
import { ContentWrapper, MainBG, textShadow } from "../../../styles/styled";
import { StaticImage } from "gatsby-plugin-image";
import buttonJoin from "../../../images/button-join.png";
import Discord from "../../../images/logo_discord.inline.svg";
import Medium from "../../../images/logo_medium.inline.svg";
import Twitter from "../../../images/logo_twitter.inline.svg";
import { BasementMenuItem, BottomSubtitle, BottomTitle, Input, socialIcon } from "./styled";
import { useSlideController, useSlideName } from "../../layout";
import { MotionImageView } from "../../Motions/MotionImageView";
import { motion } from "framer-motion";
import { useMobile } from "../../../styles/screens";
import { MotionTap } from "../../Motions/MotionTap";
import tw, { css, styled } from "twin.macro";
import Form from "../../Forms/Form";
import { bp } from "../../../styles/breakpoints";
import gplay from "../../../images/gplay.png";

export function BasementPage() {
  const ctx = useSlideController();
  const ctxSlideName = useSlideName();
  const isMobile = useMobile();

  return (
    <motion.section
      onViewportEnter={ () => ctxSlideName.setSlideName("Download") }
      viewport={ { margin: "-50%" } }
      id={ "Download" }
      tw="w-full min-h-screen h-[1px] xl:h-screen"
      css={ MainBG }
    >
      <ContentWrapper
        tw={ "flex flex-col justify-between px-2 xl:px-8 sm:px-0 pt-[8vh] sm:pt-20" }
      >
        <div tw={ "flex flex-col xl:flex-row justify-between items-center xl:flex-auto gap-x-12" }>
          <MotionImageView tw={ "lg:ml-16 max-w-[30vh] lg:max-w-[40vh] xl:w-auto xl:min-w-[350px] 2xl:min-w-[600px]" }>
            <StaticImage
              tw={ "w-full h-full" }
              src={ "../../../images/character-on-basement-page.png" }
              objectPosition={ "contain" }
              placeholder={ "none" }
              alt={ "" }
            />
          </MotionImageView>


          <div tw={ "w-full xl:w-auto flex-auto" }>
            <div tw={ "m-8 flex justify-center" }>

              <motion.div
                initial={ { opacity: 0, y: -50 } }
                whileInView={ { opacity: 1, y: 0 } }
                transition={ { delay: 0.1, duration: 0.8, type: "spring" } }
              >
                <div tw={ "mb-4 flex gap-4 justify-between" }>
                  <MotionTap>

                    <Button
                      href={ "https://download.stablebattle.io/stablebattle-setup-beta-0.2.235.e67d7325.pkg" }
                      target={ "_blank" }
                    >
                      Download<br/>MacOS Client
                    </Button>
                  </MotionTap>

                  <MotionTap>

                    <Button
                      href={ "https://download.stablebattle.io/stablebattle-setup-beta-0.2.116.5671a134.exe" }
                      target={ "_blank" }
                    >
                      Download<br/>Windows Client
                    </Button>

                  </MotionTap>

                </div>

                <MotionTap tw={ "flex justify-center" }>
                  <a
                    href={ "https://play.google.com/store/apps/details?id=io.stablebattle.android.beta" }
                    target={ "_blank" }
                  >
                    <img
                      src={ gplay }
                      alt="Google Play"
                      tw={ "block w-[180px] 2xl:w-[250px] pointer-events-none" }
                    />
                  </a>
                </MotionTap>
              </motion.div>


            </div>

            <Form/>

            <div tw="flex justify-center">
              <ul tw={ "grid grid-cols-2 gap-x-16 gap-y-2 sm:gap-y-3 2xl:gap-x-[150px] justify-between max-w-[300px] xl:max-w-[none]" }>
                <BasementMenuItem
                  onClick={ () => ctx.setSlideIndex(0) }
                ><a href={ isMobile ? "#main" : "#" }>STABLE BATTLE</a></BasementMenuItem>
                {/*<BasementMenuItem*/ }
                {/*  onClick={ () => ctx.setSlideIndex(3) }*/ }
                {/*><a href={ isMobile ? "#team" : "#" }>TEAM</a></BasementMenuItem>*/ }
                <BasementMenuItem
                  onClick={ () => ctx.setSlideIndex(1) }
                ><a href={ isMobile ? "#project" : "#" }>PROJECT</a></BasementMenuItem>
                <BasementMenuItem
                  onClick={ () => ctx.setSlideIndex(3) }
                ><a href={ isMobile ? "#faq" : "#" }>F.A.Q.</a></BasementMenuItem>
                <BasementMenuItem
                  onClick={ () => ctx.setSlideIndex(2) }
                ><a href={ isMobile ? "#partners" : "#" }>PARTNERS</a></BasementMenuItem>
              </ul>
            </div>

          </div>

        </div>
        <div tw="xl:flex flex-col xl:items-start">

          <div tw={ "flex justify-center xl:self-center items-center gap-4 lg:gap-10 mt-4 mb-12 md:mb-6 xl:mb-2 px-4" }>
            <MotionTap>
              <a
                href={ "https://twitter.com/StableBattle" }
                target={ "_blank" }
              ><Twitter css={ socialIcon }/></a>
            </MotionTap>
            <MotionTap>
              <a
                href={ "https://discord.gg/ECfUPprVmS" }
                target={ "_blank" }
              ><Discord css={ socialIcon }/></a>
            </MotionTap>
            <MotionTap>
              <a
                href={ "https://medium.com/@StableBattle" }
                target={ "_blank" }
              ><Medium css={ socialIcon }/></a>
            </MotionTap>
            {/*<Telegram css={ socialIcon }/>*/ }
          </div>


          <div tw={ "inline-flex self-start justify-start items-center gap-4 mb-8 ml-2 2xl:ml-0 md:relative lg:bottom-[40px]" }>
            <StaticImage
              tw={ "w-[10vw] md:w-[65px] lg:w-[80px]" }
              src={ "../../../images/icon_helmet.png" }
              placeholder={ "none" }
              alt={ "helmet" }
              loading={ "eager" }
            />

            <div tw={ "flex flex-row justify-between items-center md:gap-8" }>
              <div tw={ "flex flex-col" }>
                <BottomTitle>
                  QUESTIONS AND COOPERATION
                </BottomTitle>
                <BottomSubtitle>
                  <a href={ "mailto:support@stablebattle.io" }>support@stablebattle.io</a>
                </BottomSubtitle>
              </div>
            </div>

          </div>
        </div>

      </ContentWrapper>
    </motion.section>
  );
}

const Button = styled.a(({ loading }) => [
  tw`block w-[140px] lg:w-[160px] 2xl:w-[250px] text-white text-center font-bold 2xl:text-2xl`,
  css`
    background: url(${ buttonJoin });
    background-size: cover;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.9);

    ${ bp.exlg } {
      ${ tw`text-3xl` }
    }

    ${ textShadow }
  `,
  loading && tw`text-[rgba(142, 0, 0, 1)] shadow-none cursor-auto`,
]);