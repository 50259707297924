import React from "react"
import { Collapse, CollapseGroup } from "./Collapse"
import { ContentWrapper, MainBG } from "../../../styles/styled"
import { StaticImage } from "gatsby-plugin-image"
import { MotionImageView } from "../../Motions/MotionImageView"
import { motion } from "framer-motion"
import { faqData } from "./faqData"
import { useSlideName } from "../../layout"
import tw, { css } from "twin.macro"
import { bp } from "../../../styles/breakpoints"

export function FaqPage() {
  const ctx = useSlideName()

  return (
    <motion.section
      onViewportEnter={() => ctx.setSlideName("F.A.Q.")}
      viewport={{ margin: "-50%" }}
      id={"faq"}
      tw={"w-full min-h-screen xl:h-screen overflow-hidden"}
      css={MainBG}
    >
      <ContentWrapper
        tw={
          "h-full w-full flex flex-col xl:flex-row justify-between items-center xl:gap-16 xl:px-24"
        }
      >
        <motion.div
          initial={{ opacity: 0, x: "-50%" }}
          whileInView={{ opacity: 1, x: 0 }}
          tw={
            "w-[100vw] flex flex-col gap-[20px] xl:gap-2 px-3 xl:px-0 xl:w-[800px] pt-[8vh] sm:pt-24 xl:pt-14 xl:pb-0 xl:max-h-[86vh] flex-auto"
          }
        >
          <h2
            tw={
              "text-xl xl:text-2xl 2xl:text-3xl text-white text-center xl:text-left font-extrabold xl:ml-[52px]"
            }
            css={`
              text-shadow: 2px 2px 0 rgba(0, 0, 0, 1);
            `}
          >
            F.A.Q:
          </h2>
          <CollapseGroup>
            {faqData.map((item, i) => (
              <Collapse
                key={i}
                title={item.question}
                bg={item.bg}
                bgMobile={item.bgMobile}
              >
                {item.answer}
              </Collapse>
            ))}
          </CollapseGroup>
        </motion.div>

        <MotionImageView
          tw={"my-4 mb-8 mt-16 xl:mr-0 xl:mb-0 xl:mt-0 xl:flex-auto"}
        >
          <StaticImage
            tw={
              "w-full max-w-[50vw] md:max-w-[30vw] xl:w-auto xl:min-w-[350px] xl:max-w-[400px]"
            }
            src={"../../../images/character_on_the_faq_page.png"}
            css={css`
              ${bp.exlg} {
                max-width: 500px;
              }
            `}
            // objectFit={""}
            // objectPosition={"top"}
            placeholder={"none"}
            alt={"background"}
          />
        </MotionImageView>
      </ContentWrapper>
    </motion.section>
  )
}
