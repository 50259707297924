import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { SwiperSlide } from "swiper/react";
import { SwiperLayout } from "../components/SwiperLayout/SwiperLayout";
import { MainPage } from "../components/Pages/Main/MainPage";
import { ProjectPage } from "../components/Pages/Project/ProjectPage";
import { PartnersPage } from "../components/Pages/Partners/PartnersPage";
import { BasementPage } from "../components/Pages/Basement/Basement";
import { FaqPage } from "../components/Pages/FAQ/FaqPage";
import { TeamPage } from "../components/Pages/Team/TeamPage";
import { useMobile } from "../styles/screens";

const IndexPage = () => {
  const isMobile = useMobile();

  return <Layout>
    {
      !isMobile ?
        <SwiperLayout>
          <SwiperSlide>
            <MainPage/>
          </SwiperSlide>

          <SwiperSlide>
            <ProjectPage/>
          </SwiperSlide>

          <SwiperSlide>
            <PartnersPage/>
          </SwiperSlide>

          {/*<SwiperSlide>*/ }
          {/*  <TeamPage/>*/ }
          {/*</SwiperSlide>*/ }

          <SwiperSlide>
            <FaqPage/>
          </SwiperSlide>

          <SwiperSlide>
            <BasementPage/>
          </SwiperSlide>
        </SwiperLayout> : (
          <>
            <MainPage/>
            <ProjectPage/>
            <PartnersPage/>
            {/*<TeamPage/>*/ }
            <FaqPage/>
            <BasementPage/>
          </>
        )
    }

  </Layout>;


};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Stable Battle"/>;

export default IndexPage;
