import { css } from "@emotion/react"
import { bp } from "../../../styles/breakpoints"
import tw from "twin.macro"

export const companiesGrid = css`
  display: grid;
  column-gap: 1rem;
  row-gap: 1.2rem;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;

  ${bp.md} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    align-content: flex-start;
    align-items: center;
  }

  ${bp.xxl} {
    row-gap: 48px;
  }
`

export const company = css`
  max-width: 250px;

  ${bp.lg} {
    max-width: 30vh;
  }
`
