import tw, { css, styled } from "twin.macro";
import { bp } from "../../../styles/breakpoints";
import ldn from "../../../images/icon_linkedIn.inline.svg";

export const Name = styled.h3`
  ${ tw`text-base 2xl:text-xl font-bold text-center capitalize whitespace-nowrap` }
`;

export const Speciality = styled.p`
  ${ tw`text-xs md:text-sm 2xl:text-base italic text-center text-gray-100 2xl:leading-[1.3rem]` }
`;

export const Description = styled.p`
  ${ tw`font-semibold text-sm 2xl:text-base text-center 2xl:leading-[1.3rem]` }
`;

export const Social = styled.p`
  ${ tw`text-xs text-center italic font-semibold` }
  color: #00365d;
`;

export const imageStyles = css`
${tw`mr-2 xl:mr-0 xl:mb-2 max-w-[50vw] md:max-w-[30vw] xl:w-auto xl:min-w-[350px] xl:max-w-[600px] lg:w-full`}
`

export const LinkedIn = styled(ldn)`
  width: 20px;
  height: 20px;

  ${ bp.xxl } {
    width: 26px;
    height: 26px;
  }
`;

export const SwiperWrapper = styled.div`
  position: relative;
  max-height: 800px;
  width: 100%;


  ${ bp.xl } {
    overflow: hidden;
  }

  .team-swiper.swiper {
    width: 100%;
    height: 700px;
    margin-left: auto;
    margin-right: auto;

    ${ bp.xxl } {
      height: 770px;
    }

    ${ bp.exlg } {
      height: 850px;
    }

    .swiper-slide {
      width: 100%;
      height: calc(100% / 2) !important;

      cursor: pointer;
      background: transparent !important;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
`;

export const avatarStyles = css`
  ${ tw`relative -bottom-4 w-[95%]` }
`;

export const scalAvatarStyles = css`
  ${ tw`absolute inset-x-1/2 transform -translate-x-1/2 overflow-visible bottom-[-5px] w-[104%] top-auto` }
`;
