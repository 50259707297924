import React from "react";
import { motion } from "framer-motion";
import tw, { css } from "twin.macro";

export const MotionTap = (props) => {
  const { children, ...rest } = props;

  return (
    <motion.div
      whileTap={ { scale: 0.9 } }
      whileHover={ { scale: 1.1 } }
      { ...rest }
    >
      { children }
    </motion.div>
  );
};


