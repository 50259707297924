import React, { useEffect, useRef } from "react";
import { Swiper } from "swiper/react";
import { Mousewheel, Virtual } from "swiper";
import { SwiperWrapper } from "./styled";
import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/mousewheel";

import { useSlideController } from "../layout";
import tw, { css } from "twin.macro";

export function SwiperLayout(props) {
  const ctx = useSlideController();
  const swiperRef = useRef(null);

  useEffect(() => {
    swiperRef.current?.slideTo(ctx.slideIndex, 0);
  }, [ ctx.slideIndex ]);

  const onSlideChange = (swiper) => {
    if (ctx.slideIndex !== swiper.activeIndex) {
      ctx.setSlideIndex(swiper.activeIndex);
    }
  };

  return <SwiperWrapper>
    <Swiper
      direction={ "vertical" }
      slidesPerView={ 1 }
      allowTouchMove={ false }
      onSwiper={ (swiper) => {swiperRef.current = swiper;} }
      onSlideChange={ onSlideChange }
      spaceBetween={ 0 }
      mousewheel={ {
        forceToAxis: true,
        sensitivity: 0.5,
        thresholdDelta: 30,
        thresholdTime: 50,
      } }

      modules={ [ Mousewheel ] }
      className={ "page-swiper" }
    >
      { props.children }
    </Swiper>
  </SwiperWrapper>;
}